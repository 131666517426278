._btn(@color: @grey) {
  .body();

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: @spacing-xs @spacing-s;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background: @color;
  border: 1px solid @color;
  border-radius: @radius;

  .icon {
    display: inline-block;
    height: 1.2em;

    & + * {
      margin-left: @spacing-xxs;
    }
  }

  &:hover {
    background-color: darken(@color, 10%);
    border-color: darken(@color, 10%);
  }
}

.btn-block {
  display: block;
  width: 100%;

  & + .btn-block {
    margin-top: @spacing-xs;
  }
}

.btn,
.btn-1 {
  ._btn(@xx-dark-grey);
}

.btn-2 {
  ._btn(@grey);
}

.btn-danger {
  ._btn(@red);
}

.btn-duplicate {
  ._btn(@orange);
}

.btn-s {
  .body-s();

  padding: @spacing-xxs @spacing-xs;
}

._link(@color: @grey) {
  .body();

  display: inline-block;
  padding: 0;
  color: @color;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: 0;

  span {
    vertical-align: middle;

    & + span {
      margin-left: @spacing-xxs;
    }
  }

  [class^="icon-"] {
    position: relative;
    top: -0.1em;
    font-size: 1.2em;
  }

  &:hover {
    background: none;
    opacity: 0.7;
  }

  &.active,
  &:hover.active {
    .body-strong();

    color: inherit;
    opacity: 1;
  }
}

.link,
.link-1 {
  ._link(@grey);
}

.link-s,
.link-1-s {
  .link();
  .body-s();
}

.link-2 {
  ._link(@x-dark-grey);
}

.link-2-s {
  .link-2();
  .body-s();
}

.link-u,
.link-1-u {
  .link();

  text-decoration: underline;
}

.btn-icon {
  ._btn(transparent);
  .btn-s();
  .title-xs();

  border-color: @light-grey;

  [class^="icon-"] {
    color: @dark-grey;
  }

  &:hover {
    border-color: @dark-grey;
    [class^="icon-"] {
      color: @dark;
    }
  }
}
