._card(@base-padding: @spacing) {
  background-color: white;
  border: 1px solid @xxx-light-grey;
  border-radius: @radius-m;

  .card-body {
    padding: @spacing @base-padding;

    .form-footer {
      .card-body-footer();
    }
  }

  .card-head {
    padding: @base-padding*0.75 @base-padding;
    border-bottom: 1px solid @xx-light-grey;
  }

  .card-footer {
    padding: @base-padding*0.75 @base-padding;
    border-top: 1px solid @xx-light-grey;
  }
  .card-body-footer() {
    // adjusting card-footer styles when in card-body
    // used for card-body > .form-footer for example
    .card-footer();

    padding-bottom: @base-padding*0.5;
    margin-right: -@base-padding;
    margin-left: -@base-padding;
  }

  .card-title {
    .title();

    color: @x-dark-grey;
  }
  .card-subtitle {
    .title-xxs();

    color: @light-grey;
  }

  @media screen and (max-width: @bp-xs-max) {
    .card-body,
    .card-head,
    .card-footer {
      padding-right: @spacing;
      padding-left: @spacing;
    }
  }
}

.card {
  ._card();
}

._dialog-card(@max-width: 500px;) {
  ._card();

  &:not(.panel) {
    .shadow();

    width: 100%;
    max-width: @max-width;
    border: 1px solid fade(@xx-light-grey, 40%);
  }

  &.panel {
    .shadow-left-s();
  }
}

.dialog-card-s {
  ._dialog-card(400px);
}
.dialog-card {
  ._dialog-card();
}
.dialog-card-m {
  ._dialog-card(600px);
}
.dialog-card-l {
  ._dialog-card(700px);
}
.dialog-card-full {
  ._dialog-card(100%);

  min-height: 100%;
}

.auth-card {
  ._card(@spacing-l);
  .shadow();

  width: 100%;
  max-width: 400px;

  .lost-password-cta,
  .invite-admin-cta {
    padding: @spacing-xxs 0;
    text-align: right;

    a {
      .link-2();
    }
  }

  .register-cta {
    margin-top: @spacing-xs;
    text-align: center;

    a {
      .link-2();
    }
  }

  .go-back-to-login-cta {
    margin-top: @spacing-xs;
    text-align: center;

    a {
      .link-2();
    }
  }

  .card-head {
    padding-bottom: @spacing-xs;
    border-bottom: none;
  }

  .card-footer,
  .card-body .form-footer {
    border-top: none;
  }

  &.card-l {
    max-width: 800px;
  }
}
