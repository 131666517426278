@grid-gutter: @spacing;
.grid,
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -@grid-gutter / 2;

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding: @grid-gutter / 2;
  }

  &.grid-end,
  &.row-end {
    justify-content: flex-end;
  }

  &.grid-center,
  &.row-center {
    justify-content: center;
  }

  &.grid-v-center,
  &.row-v-center {
    align-items: center;
  }

  /* auto sizing columns */
  .col,
  .col-auto {
    flex: 1 0 0%;
  }
  .col-m {
    @media (min-width: @bp-m-min) {
      .col-auto();
    }
  }

  /* fixed sizes columns */
  .col-fixed {
    flex: 0 0 auto;

    &.col-sep {
      border-left: 1px solid @xx-light-grey;
    }
  }

  .col-generator(@division, @col-suffix: "", @index: 1)
    when
    not
    (@index = @division) {
    .col-@{index}-@{division} when (@col-suffix = "") {
      .col-fixed();

      width: 100% / @division* @index;
    }

    .col-@{col-suffix}-@{index}-@{division} when not (@col-suffix = "") {
      @min-width: "bp-@{col-suffix}-min";

      @media (min-width: @@min-width) {
        .col-fixed();

        width: 100% / @division* @index;
      }
    }

    .col-generator(@division, @col-suffix, @index + 1);
  }

  .col-0 {
    .col-fixed();

    width: 0%;
    padding: 0 !important;
  }
  .col-1-1 {
    .col-fixed();

    width: 100%;
  }
  .col-1-2 {
    .col-fixed();

    width: 50%;
  }
  .col-1-3 {
    .col-fixed();

    width: 33.333333%;
  }
  .col-s-1-2 {
    @media (min-width: @bp-s-min) {
      .col-1-2();
    }
  }
  .col-s-1-3 {
    @media (min-width: @bp-s-min) {
      .col-1-3();
    }
  }
  .col-generator(4, s);
  .col-m-1-1 {
    @media (min-width: @bp-m-min) {
      .col-fixed();
      .col-1-1();
    }
  }
  .col-m-1-2 {
    @media (min-width: @bp-m-min) {
      .col-fixed();

      width: 50%;
    }
  }
  .col-m-1-3 {
    @media (min-width: @bp-m-min) {
      .col-fixed();

      width: 33.3333333%;
    }
  }
  .col-m-2-3 {
    @media (min-width: @bp-m-min) {
      .col-fixed();

      width: 66.66666666%;
    }
  }
  .col-m-1-4 {
    @media (min-width: @bp-m-min) {
      .col-fixed();

      width: 25%;
    }
  }
  .col-generator(6, m);

  .col-generator(5, m);

  .col-l-1-4 {
    @media (min-width: @bp-l-min) {
      .col-fixed();

      width: 25%;
    }
  }
  .col-l-1-3 {
    @media (min-width: @bp-l-min) {
      .col-fixed();

      width: 33.333333%;
    }
  }
  .col-l-1-2 {
    @media (min-width: @bp-l-min) {
      .col-fixed();

      width: 50%;
    }
  }
  .col-l-2-3 {
    @media (min-width: @bp-l-min) {
      .col-fixed();

      width: 66.666666%;
    }
  }

  .col-generator(5, l);

  .col-xl-1-2 {
    @media (min-width: @bp-xl-min) {
      .col-fixed();

      width: 50%;
    }
  }
  .col-xl-1-3 {
    @media (min-width: @bp-xl-min) {
      .col-fixed();

      width: 33.333333%;
    }
  }
}
.row {
  flex-wrap: nowrap;

  /* fitting column */
  .col-fit {
    flex: 0 0 auto;
    width: auto;
  }
}
.row-s {
  @media (min-width: @bp-s-min) {
    .row();
  }
}
.row-m {
  @media (min-width: @bp-m-min) {
    .row();
  }

  [class^="col"] + [class^="col"] {
    @media (max-width: @bp-s-max) {
      margin-top: @spacing-s;
    }
  }
}

._buttons-row(@bp: 0) {
  @gutter: @spacing-xxs;

  display: flex;
  flex-direction: column;
  margin-top: -@gutter;
  margin-left: 0;

  & > * {
    margin-top: @gutter;
  }

  @media (min-width: @bp) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
    margin-left: -@gutter;

    & > * {
      margin: 0 @gutter;
    }
  }
}
.buttons-row {
  ._buttons-row();
}
.buttons-row-s {
  ._buttons-row(@bp-s-min);
}
