.info {
  display: block;
  padding: @spacing-s @spacing;
  color: white;
  white-space: normal;
  background: @xx-dark-grey;
  border-radius: @radius;

  &,
  p {
    .body-xs();
  }

  &.info-secondary {
    color: @dark-grey;
    background: @xx-light-grey;
  }

  &.info-success {
    color: @green;
    background: @light-green;
  }
  &.info-warning {
    color: @yellow;
    background: @light-yellow;
  }
  &.info-error {
    color: @red;
    background: @light-red;
  }

  // modifiers
  &.info-s {
    padding: @spacing-xs @spacing-s;
  }
  &.info-m {
    &,
    p {
      .body();
    }
  }
}

.info-loading {
  .info();
  .info-secondary();
  .container();
  .center();

  border-radius: 0;

  button {
    margin-left: @spacing-s;
  }
}

.form-errors {
  .info();
  .info-error();
  .center();

  margin: @spacing 0 0 0;
  list-style-type: none;

  li {
    display: block;
  }
}
