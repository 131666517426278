._input {
  .body();

  display: block;
  width: 100%;
  padding: @spacing-xs @spacing-s;
  color: @x-dark-grey;
  background: white;
  border: 1px solid @xxx-light-grey;
  border-radius: @radius;
  appearance: none;

  &[disabled] {
    ._input-disabled();
  }
}

._input-disabled {
  color: @grey;
  cursor: not-allowed;
  background: fade(@xx-light-grey, 40%);
}

.input,
.input-1 {
  ._input();
}

.input-label {
  .body-s();
  .body-strong();

  color: @grey;
}

.input-tip {
  .body-xs();

  color: @light-grey;
}

.textarea {
  ._input();

  resize: vertical;
}

.select {
  ._input();

  appearance: none;
  cursor: pointer;
  background-image: url("../img/icons/icon-chevron-down-grey.svg");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 1em;
  .truncate();

  &.fit-content {
    width: auto;
  }
}

.form-block {
  & + & {
    margin-top: @spacing-xs;
  }
}

.form-footer {
  padding: @spacing-s 0;
  margin-top: @spacing-s;
}

.theme-light .input {
  .input-light-theme();
}

.input-light-theme() {
  border-color: @xx-light-grey;
}

fieldset {
  padding: 0;
  border: none;

  &[disabled] {
    input,
    textarea,
    select {
      ._input-disabled();
    }
  }
}

.sx-select {
  ._input();

  display: flex;
  align-items: center;

  .selected-name {
    margin-right: auto;
  }

  [class^="btn-"] {
    width: 1em;
    height: 1em;
    padding: @spacing-xs;
    margin: 0 2px;
    border-radius: 50%;
  }
}

.filter {
  display: flex;
  column-gap: @spacing-xs;
  align-items: center;
  justify-content: flex-start;
  margin-top: @spacing-s;
  .select {
    min-width: 10em;
    margin-top: @spacing-xxs;
  }
}
