.section-title {
  color: @orange;
}
.auth-card {
  background: fade(white, 80%);
  border-color: fade(white, 50%);

  .section-title {
    color: @dark-grey;
  }
}
.site-layout {
  .header {
    background: fade(@dark, 50%);
  }
  .layout-content.auth-layout-content::before {
    background-image: url(../img/auth-bg.jpeg);
  }
  .footer {
    background: fade(@dark, 50%);

    .nav-item {
      color: @light-grey;

      &.active {
        color: white;
      }
    }
  }
}
.app-layout,
.app-layout-1 {
  & > .header {
    background-color: @dark;

    .nav-item {
      color: fade(white, 90%);
    }

    .menu-button {
      border-color: @orange;

      [class^="icon-"] {
        color: @orange;
      }
    }
  }
  .sidebar {
    padding-top: @spacing;
    background-color: @dark;

    .sidebar-section {
      .section-title {
        background: inherit;
        border-bottom: 1px solid fade(white, 10%);
      }
    }

    .sidebar-nav {
      .nav-item {
        color: fade(white, 90%);

        &:hover:not(.active) {
          color: @orange;
          background: fade(white, 10%);

          &::before {
            background: @orange;
          }
        }
        &.active {
          color: @dark;
          background: @orange;

          &::before {
            background: darken(@orange, 20%);
          }
        }
      }
    }
  }
}

.content-view {
  .head {
    .view-title {
      color: @dark;
    }
  }
}

.btn-1 {
  ._btn(@dark);

  color: @orange;

  &.btn-s {
    .btn-s();
  }
}

.tab-item {
  &.active,
  &.active:hover {
    color: @orange;

    &::after {
      background: @orange;
    }
  }
}

.list {
  a.list-item,
  .list-item.has-actions,
  .list-item.has-actions > a {
    &:hover {
      background-color: mix(@orange, white, 15%);

      .item-title {
        color: @orange;
      }
    }
  }
}

.table {
  tbody {
    tr {
      &:not(.table-recap-row):nth-child(2n) {
        background: fade(@orange, 5%);
      }
    }
  }
  td {
    border-color: fade(@orange, 15%);
  }
  .table-recap-row {
    color: @dark;
    background: @orange;
  }
  thead tr:last-child th {
    border-color: @orange;
  }
}

.head .tabs {
  align-items: end;

  .tab-item::after {
    display: block;
    width: 100%;
    height: 2px;
    margin-top: calc(5px - 2px);
    content: "";
  }

  .tab-item-special {
    .tab-item();
    .btn-1();
    .btn-s();

    margin-top: @spacing-xxs;
    margin-left: auto;
  }
}
