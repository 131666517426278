.list {
  .list-item {
    display: block;
    padding: @spacing-s;
    text-align: left;
    background: white;

    .item-title {
      .body();
      .body-strong();
      .truncate();

      color: @dark-grey;
    }

    .item-meta-primary {
      .body-s();
      .body-strong();
      .truncate();

      color: @grey;
    }

    .item-meta-secondary {
      .body-xs();
      .body-strong();
      .truncate();

      color: @light-grey;

      &:not(& + .item-meta-secondary) {
        margin-top: @spacing-xxs;
      }
    }

    & + .list-item {
      margin-top: @spacing-xxs;
    }

    &.has-actions {
      display: flex;

      & > * {
        flex: 1 0 auto;
      }

      .item-actions {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        margin-left: auto;
      }
    }

    &:hover,
    & > a:hover {
      background: @xx-light-grey;
      opacity: 1;
    }
  }
}

.theme-light {
  .list .list-item {
    background-color: @xxx-light-grey;

    &:hover {
      background: @xx-light-grey;
    }
  }
}
