/* alignment helpers */
.center {
  text-align: center;
}
.right {
  text-align: right;
}

/* miscellaneous helpers */
.truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auto-scroll {
  overflow-y: auto;
}
