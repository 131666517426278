@import "variables";
@import "helpers";
@import "colors";
@import "typo";
@import "layout";
@import "nav";
@import "buttons";
@import "cards";
@import "forms";
@import "shadows";
@import "dialogs";
@import "infos";
@import "tabs";
@import "lists";
@import "tables";
@import "icon-font";

@import "theme-regulappro";

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

/* temporary styles, to be refactored and/or dispatched in specific files when validated */
.section {
  padding-top: @spacing;
  padding-bottom: @spacing;
}
.section-s {
  padding-top: @spacing-s;
  padding-bottom: @spacing-s;
}

.icon {
  width: 20px;
  max-height: 20px;
}

.chip {
  .body-s();

  display: inline-flex;
  align-items: center;
  padding: @spacing-xxs @spacing-s;
  color: white;
  background: @grey;
  border-radius: @spacing-xl;
}

.btn-star-active {
  color: @dark;
  background-color: @orange;
  border-color: @orange;

  &:hover {
    color: white;
  }
}
