/* FONTS */
.main-font {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

/* WEIGHTS */
.thin {
  font-weight: 100;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.semibold {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}

/* TYPOGRAPHIC STYLES */
.title-xxs {
  .title();

  font-size: 0.9rem;
}
.title-xs {
  .title();

  font-size: 1rem;
}
.title-s {
  .title();

  font-size: 1.2rem;
}
.title {
  .main-font();
  .bold();

  font-size: 1.4rem;
  line-height: 1.2;
}
.body-xs {
  .body();

  font-size: 0.7rem;
}
.body-s {
  .body();

  font-size: 0.8rem;
}
.body {
  .main-font();
  .regular();

  font-size: 0.9rem;
  line-height: 1.2;
}
.body-strong() {
  .semibold();
}

.body-alert {
  color: @red;
}

/* SEMANTIC TYPOGRAPHIC STYLES */
.section-title {
  .title-s();

  color: @x-dark-grey;
}

/* DEFAULT TYPOGRAPHIC STYLES */
html,
body {
  .main-font();

  padding: 0;
  margin: 0;
  color: @x-dark-grey;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  .title();

  padding: 0;
  margin: 0;
}
p {
  padding: 0;
  margin: 0.5em 0;
  .body();
}
a {
  .link();
}
strong {
  .body-strong();
}
::selection {
  color: @selection-font-color;
  background-color: @selection-bg-color;
}
