@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?uuz3nc");
  src: url("../fonts/icomoon.eot?uuz3nc#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?uuz3nc") format("truetype"),
    url("../fonts/icomoon.woff?uuz3nc") format("woff"),
    url("../fonts/icomoon.svg?uuz3nc#icomoon") format("svg");
  font-style: normal;
  font-weight: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logout::before {
  content: "\e902";
}
.icon-user::before {
  content: "\e903";
}
.icon-close::before {
  content: "\e900";
}
.icon-burger-menu::before {
  content: "\e901";
}
