@import "grid-system.less";

@container-padding: @spacing-xl;
@container-padding-responsive: @spacing;

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  padding-right: @container-padding;
  padding-left: @container-padding;

  @media (max-width: @bp-m-max) {
    padding-right: @container-padding-responsive;
    padding-left: @container-padding-responsive;
  }
}

.container-wrap {
  .container();

  max-width: @bp-xl-min;
  margin: 0 auto;
}

._section(@size: @spacing) {
  padding-top: @size;
  padding-bottom: @size;

  &.section-indent {
    position: relative;
    padding-left: @size;

    &::before {
      position: absolute;
      top: @size;
      bottom: @size;
      left: 0;
      width: 0;
      content: "";
      border-left: 2px solid @xx-light-grey;
    }
  }
}

.section {
  ._section(@spacing);
}
.section-xs {
  ._section(@spacing-xs);
}
.section-s {
  ._section(@spacing-s);
}

._content-block(@size: @spacing) {
  margin-top: @size;
  margin-bottom: @size;
}

.content-block {
  ._content-block(@spacing);
}
.content-block-xs {
  ._content-block(@spacing-xs);
}
.content-block-s {
  ._content-block(@spacing-s);
}

.page-content {
  padding-top: @spacing-l;
  padding-bottom: @spacing-l;

  [class^="section"]:first-child,
  [class^="block"]:first-child {
    padding-top: 0;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    &:not(.panel) {
      padding-bottom: @spacing-xxl;
    }
  }
}

.layout() {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background-color: @app-bg-color;

  .menu-button {
    display: none;

    [class^="icon-"] {
      transition: transform 0.2s linear;
    }

    .icon-burger-menu {
      transform: rotateZ(0);
    }

    .icon-close {
      transform: rotateZ(90deg);
    }
  }

  & > .header {
    .container();

    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: @spacing-xs;
    padding-bottom: @spacing-xs;
    background-color: @xx-light-grey;

    .logo-header {
      display: block;
      max-width: 200px;
      max-height: @spacing-l;
    }

    & > .nav {
      display: flex;
      align-items: center;

      &:last-child {
        margin-right: -@nav-items-spacing;
      }
    }
  }

  & > .layout-content {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
  }

  & > .footer {
    .container();

    z-index: 1;
    padding-top: @spacing-xs;
    padding-bottom: @spacing-xs;
    text-align: center;
    background-color: @whitish;
    border-top: 1px solid fade(black, 3%);

    .copyright {
      .body-xs();

      color: @grey;
    }
  }

  .sidebar {
    flex: 0 0 auto;
    width: @app-layout-sidebar-width;
    background-color: @xx-light-grey;
    transition: margin-left 0.2s linear;

    .sidebar-section {
      &:not(:first-child) {
        margin-top: @spacing-s;
      }

      .section-title {
        .container();
        .body-s();
        .body-strong();

        padding-top: @spacing-xxs;
        padding-bottom: @spacing-xxs;
        margin-bottom: @spacing-xxs;
        color: white;
        background: @grey;
      }
    }

    .sidebar-nav {
      line-height: 0;

      .nav-item {
        .container();
        .body-s();

        position: relative;
        display: block;
        padding-top: @spacing-xs;
        padding-bottom: @spacing-xs;
        margin: 0;
        line-height: 1;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: @spacing-xs;
          height: 100%;
          content: "";
          background: transparent;
        }

        &:hover {
          opacity: 1;

          &:not(.active) {
            color: @x-dark-grey;
            background: fade(white, 30%);

            &::before {
              background: @light-grey;
            }
          }
        }

        &.active {
          .body-strong();

          color: @x-dark-grey;
          background: @x-light-grey;

          &::before {
            background: @x-dark-grey;
          }
        }
      }
    }
  }

  .content-view {
    width: 100%;
  }

  @media (max-width: @bp-m-max) {
    .menu-button {
      display: inline-flex;

      & + * {
        margin-left: @spacing-xxs;
      }
    }

    .sidebar {
      .shadow-right-s();

      margin-left: -@app-layout-sidebar-width;

      &.expanded {
        position: relative;
        z-index: 2;
        max-width: calc(100% - @spacing);
        margin-left: 0;

        & + .content-view::before {
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          display: block;
          content: "";
          background: fade(black, 30%);
        }
      }
    }

    .content-view {
      min-width: 100vw;
    }
  }

  @media (max-width: @bp-s-max) {
    .header .nav .nav-item {
      .title-s();

      & > [class^="icon-"] + span {
        display: none;
      }
    }
  }
  @media (max-width: @bp-xs-max) {
    & > .header .logo-header {
      max-width: 150px;
    }
  }
}

.app-layout,
.app-layout-1 {
  .layout();

  &.sticky {
    height: 100vh;
    overflow: hidden;

    .layout-content {
      height: 1px;
    }
  }
}

.content-view {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  & > * {
    flex: 0 0 auto; // à surcharger au cas par cas si besoin. en principe stretch uniquement sur le .content
  }

  .head {
    .shadow-border();

    padding-top: @spacing-s;
    padding-bottom: @spacing-s;
    background-color: white;

    .back-button {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      width: auto;

      & > img {
        width: @spacing-xs;
      }
    }

    .breadcrumb {
      a {
        .link-s();

        text-decoration: underline;
      }

      .breadcrumb-chevron {
        display: inline-block;
        height: 0.6em;
      }
    }

    .view-title {
      .title-s();

      color: @dark-grey;
    }

    .tabs {
      margin-top: @spacing-s;
      margin-bottom: -@spacing-s;
    }
  }

  .view-head-actions {
    [class^="btn"] {
      .btn-s();
    }
  }

  .content {
    flex: 1 0 auto;
  }

  .action-bar {
    .container();
    .shadow-border();

    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    width: calc(100% - @app-layout-sidebar-width);
    height: @content-view-action-bar-height;
    padding-top: @spacing-xxs;
    padding-bottom: @spacing-xxs;
    background: white;

    [class^="btn"] {
      .btn-s();

      & + [class^="btn"] {
        margin-left: @spacing-xs;
      }
    }
  }

  .container .action-bar {
    margin-left: -@container-padding;

    @media (max-width: @bp-m-max) {
      width: 100%;
      margin-left: -@container-padding-responsive;
    }
  }

  &.sticky {
    overflow: hidden;

    & > .content {
      height: 1px;
      overflow-y: auto;
    }
  }

  &.has-sticky-bottom {
    padding-bottom: @content-view-action-bar-height;
  }
}

.site-layout {
  .app-layout();

  .header,
  .footer {
    background: rgba(255, 255, 255, 0.7);
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .layout-content.auth-layout-content {
    .container();
    .page-content();

    z-index: 0;
    align-items: center;
    justify-content: center;

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100vw;
      height: 100vh;
      content: "";
      background: no-repeat center center;
      background-image: linear-gradient(
          112.5deg,
          rgba(232, 232, 232, 0.03) 0%,
          rgba(232, 232, 232, 0.03) 2%,
          rgba(231, 231, 231, 0.03) 2%,
          rgba(231, 231, 231, 0.03) 4%,
          rgba(231, 231, 231, 0.03) 4%,
          rgba(231, 231, 231, 0.03) 11%,
          rgba(2, 2, 2, 0.03) 11%,
          rgba(2, 2, 2, 0.03) 67%,
          rgba(231, 231, 231, 0.03) 67%,
          rgba(231, 231, 231, 0.03) 90%,
          rgba(111, 111, 111, 0.03) 90%,
          rgba(111, 111, 111, 0.03) 100%
        ),
        linear-gradient(
          157.5deg,
          rgba(210, 210, 210, 0.03) 0%,
          rgba(210, 210, 210, 0.03) 17%,
          rgba(254, 254, 254, 0.03) 17%,
          rgba(254, 254, 254, 0.03) 18%,
          rgba(96, 96, 96, 0.03) 18%,
          rgba(96, 96, 96, 0.03) 44%,
          rgba(159, 159, 159, 0.03) 44%,
          rgba(159, 159, 159, 0.03) 70%,
          rgba(24, 24, 24, 0.03) 70%,
          rgba(24, 24, 24, 0.03) 82%,
          rgba(16, 16, 16, 0.03) 82%,
          rgba(16, 16, 16, 0.03) 100%
        ),
        linear-gradient(
          22.5deg,
          rgba(47, 47, 47, 0.03) 0%,
          rgba(47, 47, 47, 0.03) 32%,
          rgba(124, 124, 124, 0.03) 32%,
          rgba(124, 124, 124, 0.03) 40%,
          rgba(200, 200, 200, 0.03) 40%,
          rgba(200, 200, 200, 0.03) 42%,
          rgba(16, 16, 16, 0.03) 42%,
          rgba(16, 16, 16, 0.03) 64%,
          rgba(243, 243, 243, 0.03) 64%,
          rgba(243, 243, 243, 0.03) 94%,
          rgba(93, 93, 93, 0.03) 94%,
          rgba(93, 93, 93, 0.03) 100%
        ),
        linear-gradient(90deg, white, white);
      background-size: cover;
    }
  }
}
