.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -@spacing-xs;
}

.tab-item {
  .link();
  .link-s();

  padding: 0 @spacing-xs;
  color: @dark;
  text-transform: uppercase;

  &.active,
  &.active:hover {
    color: @dark-grey;

    &::after {
      display: block;
      width: 100%;
      height: 2px;
      margin-top: calc(@spacing-xxs - 2px);
      content: "";
      background: @dark-grey;
    }
  }
}
